import { named, withDependencies } from '@wix/thunderbolt-ioc'
import {
	FeatureStateSymbol,
	IPageWillMountHandler,
	IPageWillUnmountHandler,
	PageFeatureConfigSymbol,
} from '@wix/thunderbolt-symbols'
import { WindowScrollApiSymbol, IWindowScrollAPI } from 'feature-window-scroll'
import { TOP_AND_BOTTOM_ANCHORS } from './constants'
import { name } from './symbols'
import type { ISamePageScroll, ScrollToAnchorPageConfig } from './types'
import { IFeatureState } from 'thunderbolt-feature-state'
import _ from 'lodash'

type SamePageScrollState = ScrollToAnchorPageConfig['anchorDataIdToCompIdMap']

export const samePageScrollFactory = (
	{ anchorDataIdToCompIdMap }: ScrollToAnchorPageConfig,
	featureState: IFeatureState<SamePageScrollState>,
	windowScrollApi: IWindowScrollAPI
): ISamePageScroll & IPageWillMountHandler & IPageWillUnmountHandler => {
	return {
		pageWillMount() {
			// merge page and masterPage's pageConfigs so features using samePageScroll will be able to scroll to any anchor from any container
			featureState.update((state) => ({ ...state, ...anchorDataIdToCompIdMap }))
		},
		scrollToAnchor: ({ anchorCompId, anchorDataId }) => {
			if (anchorDataId && TOP_AND_BOTTOM_ANCHORS.includes(anchorDataId)) {
				windowScrollApi.scrollToComponent(anchorDataId)
				return true
			}
			if (anchorCompId) {
				windowScrollApi.scrollToComponent(anchorCompId)
				return true
			}
			if (anchorDataId && featureState.get()[anchorDataId]) {
				// in responsive the anchorData doesn't include the comp id
				windowScrollApi.scrollToComponent(featureState.get()[anchorDataId])
				return true
			}

			return false
		},
		pageWillUnmount() {
			// avoid hold onto anchors from dead pages
			featureState.update((state) => _.omit(state, Object.keys(anchorDataIdToCompIdMap)))
		},
	}
}

export const SamePageScroll = withDependencies(
	[named(PageFeatureConfigSymbol, name), named(FeatureStateSymbol, name), WindowScrollApiSymbol],
	samePageScrollFactory
)
